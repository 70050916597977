import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

import styles from './article-preview.module.css'

const StyledLink = styled(Link)`
  text-decoration: none;
  box-shadow: 0px 12px 30px -10px rgba(32, 32, 28, 0.08);
  &:hover {
    > .gatsby-image-wrapper {
      box-shadow: 0px 12px 30px -10px rgba(32, 32, 28, 0.32);
    }
  }
`

const ArticleTitle = styled.h3`
  color: #20201c;
  margin-block-end: 1rem;
  margin-block-start: 0.5rem;
  font-weight: 500;
  line-height: 1.25;
  &:hover {
      text-decoration: underline;
  }
`

const StyledSmall = styled.small`
  display: block;
  font-size: 1em;
  color: #000;
  display: inline-block;
  margin-inline-end: 0.5rem;
  margin-block-end: 0.5rem;
  line-height: 1.25;
`

const StyledTag = styled.p`
  color: hsla(149, 32%, 15%, 1);
  background-color: hsla(149, 85%, 18%, 0.10);
  text-decoration: none;
  display: inline-block;
  padding: 0.33rem 1rem;
  line-height: 1;
  border-radius: 16px;
  margin: 0 0.5rem 0.25rem 0;
  font-weight: 700;
  height: 1.25rem;
`

const StyledInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    flex-direction: column;
    display: block;
  }
`

const StyledTags = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledImg = styled(Img)`
  border-radius: 32px;
  margin-block-end: 2rem;
  margin-inline-start: -0.25rem;
  @media (max-width: 600px) {
    border-radius: 16px;
  }
`

export default ({ article }) => (
  <div className={styles.preview}>
    <StyledLink to={`/blog/${article.slug}`}>
      <StyledImg alt="" fluid={article.heroImage.fluid} />
      <ArticleTitle className={styles.previewTitle}>
        {article.title}
      </ArticleTitle>
    </StyledLink>
    <StyledInfo>
      <StyledSmall>{article.publishDate}</StyledSmall>
      <StyledTags>
        {article.tags &&
          article.tags.map((tag) => <StyledTag key={tag}>{tag}</StyledTag>)}
      </StyledTags>
    </StyledInfo>
  </div>
)
